<template>
    <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
                  <div class="card">
                     <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                           <h4 class="card-title">배대지 사용자 현황</h4>
                        </div>
                     </div>
                     <div class="card-body">
                        <div>
                           <label>사용자등록(쿠플러스 이메일주소)</label>
                           <input type="text" class="form-control" aria-label="" v-model="regEmail" @change="activeemail" style="text-align:center;">
                        </div>
                        <div class="table-responsive">
                           
                           <table id="user-list-table" class="table table-striped dataTable mt-4" role="grid" aria-describedby="user-list-page-info">
                           <thead>
                                 <tr class="ligth">
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Company ID</th>
                                    <th>Join Date</th>
                                    <th>Memo</th>
                                    <th style="min-width: 100px">Authority</th>
                                 </tr>
                           </thead>
                           <tbody>
                                 <tr v-for="(userlist, index) in userlists" :key="index">
                                    <td>{{userlist.name}}</td>
                                    <td>{{userlist.phone}}</td>
                                    <td>{{userlist.email}}</td>
                                    <td><span :class="[badge,bgcolor(userlist.approval)]">{{userlist.approval}}</span></td>
                                    <td>{{userlist.companyid}}</td>
                                    <td>{{userlist.regdate}}</td>
                                    <td><input type="text" class="form-control" aria-label="" v-model="userlist.memo" @change="changeusermemo(userlist)" style="text-align:center;"></td>
                                    <td>
                                       <div class="flex align-items-center list-user-action" v-b-modal="modalID(index)">
                                          <a class="btn btn-sm bg-primary mr-1" v-b-tooltip.top title="Edit"  href="#"><i class="ri-pencil-line mr-0 "></i></a>
                                             <b-modal :id="'modal'+index" size="xl" title="권한수정" ok-title="OK" cancel-title="Close">
                                                 <b-form @submit="onSubmit" @reset="onReset">
                                                   
                                                   <b-form-group id="input-group-4" label="Food:" label-for="checkboxes-4">
                                                      <b-form-checkbox-group
                                                         v-model="auth"
                                                         id="checkboxes-4"
                                                         :aria-describedby="ariaDescribedby"
                                                      >
                                                      {{auth}}
                                                      <b-form-checkbox value="product">Products</b-form-checkbox>
                                                      <b-form-checkbox value="polist">PO List</b-form-checkbox>
                                                      <b-form-checkbox value="stock">Stock</b-form-checkbox>
                                                      <b-form-checkbox value="purchase">Purchases</b-form-checkbox>
                                                      <b-form-checkbox value="return">Returns</b-form-checkbox>
                                                      <b-form-checkbox value="admin">Admin function(Userlist, Reports)</b-form-checkbox>
                                                   </b-form-checkbox-group>
                                                   </b-form-group>

                                                   <b-button type="submit" variant="primary">Submit</b-button>
                                                   <b-button type="reset" variant="danger">Reset</b-button>
                                                </b-form>
                                                <p>상품명 : {{ProductList.상품명}}<br>SKU ID : {{ProductList["SKU ID"]}}<br>바코드 : {{ProductList.바코드}}</p>
                                                <img :src="ProductList.등록이미지" style="width:50%;display: block; margin: 0px auto; border:1px solid silver; border-radius: 30px">
                                                
                                             </b-modal>

                                       </div>
                                    </td>
                                 </tr>
                                 
                           </tbody>
                           </table>
                        </div>
                           <!-- <div class="row justify-content-between mt-3">
                              <div id="user-list-page-info" class="col-md-6">
                                 <span>Showing 1 to 5 of 5 entries</span>
                              </div>
                              <div class="col-md-6">
                                 <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end mb-0">
                                       <li class="page-item disabled">
                                          <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                       </li>
                                       <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                       <li class="page-item"><a class="page-link" href="#">2</a></li>
                                       <li class="page-item"><a class="page-link" href="#">3</a></li>
                                       <li class="page-item">
                                          <a class="page-link" href="#">Next</a>
                                       </li>
                                    </ul>
                                 </nav>
                              </div>
                           </div> -->
                     </div>
                  </div>
            </div>
         </div>
      </div>
</template>
<script>
import axios from 'axios';
export default {
   name:'UserList',
   data() {
      return {
         userlists:[],
         auth:[],
         regEmail:'',
      }
   },
   methods: {
      activeemail(){
         if(!this.regEmail){
            alert('이메일주소를 입력해 주세요')
            return
         } else {
            axios.post('/api/activeUserEmail',{email: this.regEmail})
             .then(result => {
               if(result.data.res == 'success'){
                  this.userlists.unshift(result.data.result);
               } else {
                  alert(result)
               }
            })
             .catch(console.log)
         }
      },
      changeusermemo(userlist){
         console.log(userlist)
      },
      modalID(index) {
         return 'modal' + index
      },
      bgcolor(approval){
         if(approval == true) {
            return 'bg-success'
         } else {
            return 'bg-secondary'
         }
      },
      onSubmit(event) {
         event.preventDefault()
         if (this.auth.length > 0){
            var authobject = {
               showdashboard : true,
               showproduct : this.auth.includes("product"),
               showpolist : this.auth.includes("polist"),
               showstock : this.auth.includes("stock"),
               showpurchase : this.auth.includes("purchase"),
               showreturn : this.auth.includes("return"),
               showdeveloper : false,
               showadmin : this.auth.includes("admin")
            };
             this.$store.commit('userauthupdate', {auth : authobject})
             axios.post('/userauthupdate',{email: this.$store.state.user.email, auth : authobject})
             .then(result => console.log(result.data.response))
             .catch(console.log)
         }
      },
      onReset(event) {
         event.preventDefault();
         this.auth = [];
      },  
   },
   mounted() {
      this.$nextTick(function () {
         if(this.$store.state.user.auth.showadmin == true) {
            axios.post('/api/userlist',{companyid : this.$store.state.user.companyid})
            .then(result => {
               if(result.data.res == 'success'){
                  result.data.result.forEach(e => {
                     if(!e.memo){
                        e.memo = ''
                     }
                  })
                  this.userlists = result.data.result
               } else {
                  alert(result)
               }
            })
            .catch(console.log)
         }
      })
   }

}
</script>
